import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
    namespaced: true,
    state: {
        module: 'tarea',
        loading: false,
        tareas: [],
    },
    getters: {},
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_TAREAS(state,payload){
            state.tareas=payload
        }
    },
    actions: {
        async fetchTareas({ state, commit }, payload) {
            store.commit('app/RESET_ALERT')
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                const res = await controller.getAllTarefas(payload)
                commit('SET_TAREAS',res.data)
                return res
            } catch (e) {
                console.log(e)
                store.commit('app/SET_ALERT', messageAlerts.errorAlert)
                return []
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async listTareas({ state, commit }, payload) {
            commit('SET_LOADING', true)
            store.commit('app/RESET_ALERT')
            const controller = useModule(state.module)
            try {
                return await controller.listWithoutPaginationTarea(payload)
            } catch(e) {
                console.log(e)
                store.commit('app/SET_ALERT', messageAlerts.errorAlert)
                return []
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async addTarea({ commit, state }, tareaData) {
            store.commit('app/RESET_ALERT')
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                await controller.createTarea(tareaData)
                store.commit('app/SET_ALERT', messageAlerts.a200Alert)
                return null
            } catch(e) {
                console.log(e)
                store.commit('app/SET_ALERT', messageAlerts.errorAlert)
                return null
            } finally {
                commit('SET_LOADING', false)
            }
        },

        async editTarea({ commit, state },payload ) {
            store.commit('app/RESET_ALERT')
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
              await controller.updateTarea(payload)
              store.commit('app/SET_ALERT', messageAlerts.a200Alert)
              return null
            } catch {
              store.commit('app/SET_ALERT', messageAlerts.errorAlert)
              return null
            } finally {
              commit('SET_LOADING', false)
            }
          },

        async deleteTarea({ commit, state }, tareaData) {
            store.commit('app/RESET_ALERT')
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
              await controller.delete(tareaData)
              store.commit('app/SET_ALERT', messageAlerts.a200Alert)
              return null
            } catch {
              store.commit('app/SET_ALERT', messageAlerts.errorAlert)
              return null
            } finally {
              commit('SET_LOADING', false)
            }
          },
    }
}