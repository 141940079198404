<template>
  <div>
    <tarea-list-add-new
      :is-add-new-tarea-sidebar-active.sync="isAddNewTareaSidebarActive"
      :edit-item="editItem"
      @closeDialog="closeDialog"
      :tareasList="tareasList"
      @rowClicked="rowClicked"
      @addTarea="addTarea"
      @refetch-data="refetchData"
    />

    <b-card no-body :aria-hidden="loading ? 'true' : null">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button variant="primary" @click="createTarea()">
                <span class="text-nowrap">Crear Tarea</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refTareasListTable"
        class="position-relative"
        :items="fetchTareas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchTareas"
        @row-clicked="rowClicked($event.item)"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="
              isAddNewTareaSidebarActive = true;
              editItem = data.item;
            "
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column:  -->
        <template #cell(nombre)="data">
          <p>
            {{ data.item.parent ? data.item.nombre : '' }}
          </p>
        </template>

        <!-- Column:  -->
        <template #cell(parent.nombre)="data">
          <p>
            {{ data.item.parent ? data.item.parent.nombre : data.item.nombre }}
          </p>
        </template>
        <template #cell(actions)="data">
          <b-row align-h="center">
            <b-button
              :id="`edit-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="success"
              @click="
                isAddNewTareaSidebarActive = true;
                selectedItem = data.item;
                editTarea(data.item);
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              :id="`delete-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="danger"
              @click="
                deleteModal = true;
                selectedItem = data.item;
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-row>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTareas"
              :per-page="perPage"
              aria-controls="tareasTable"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template v-slot:prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template v-slot:next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el tarea."
      title="¿Está seguro de que desea eliminar el tarea?"
      @action="deleteTarea(selectedItem.id)"
      @close="deleteModal = false"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import useTareasList from "@/views/work/tareas/useTareasList";
import vSelect from "vue-select";
import TareaListAddNew from "@/views/work/tareas/TareasForm.vue";
import store from "@/store";
import tareaStoreModule from "@/views/work/tareas/tareaStoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import ConfirmationModal from "@/components/ConfirmationModal.vue";

export default {
  components: {
    TareaListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
    vSelect,
    ConfirmationModal,
  },

  data() {
    return {
      deleteModal: false,
      isAddNewTareaSidebarActive: false,
      editItem: null,
      selectedItem: {},
    };
  },

  methods: {
    statusColor(status) {
      return status === "invited"
        ? "warning"
        : status === "disabled"
        ? "dark"
        : status == false
        ? "danger"
        : "success";
    },
    rowClicked(item) {
      this.selectedRow = item;
    },
    async createTarea() {
      this.isAddNewTareaSidebarActive = true;
      await this.refetchData;
    },
    addTarea(obj) {
      this.tareaSelect(obj);
    },
    closeDialog() {
      this.isAddNewTareaSidebarActive = false;
      this.editItem = null;
    },
    deleteTarea(id) {
      this.deleteModal = true;
      this.$store.dispatch("app-tarea/deleteTarea", id).then(() => {
        this.deleteModal = false;
        this.refetchData();
        this.removeTareaFromSelect(id);
      });
    },
    editTarea(tarea) {
      this.isAddNewTareaSidebarActive = true;
      this.editItem = tarea;
    },
  },
  computed: {
    loading() {
      return this.$store.state["app-tarea"].loading;
    },
  },
  setup() {
    const TAREAS_APP_STORE_MODULE_NAME = "app-tarea";

    if (!store.hasModule(TAREAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(TAREAS_APP_STORE_MODULE_NAME, tareaStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(TAREAS_APP_STORE_MODULE_NAME))
        store.unregisterModule(TAREAS_APP_STORE_MODULE_NAME);
    });

    const isAddNewTareaSidebarActive = ref(false);
    const editItem = ref(null);
    const deleteItem = ref(null);
    const listOfTareas = [];
    const refreshItem = ref(null);
    const tareasList = ref([]);

    const {
      fetchTareas,
      tableColumns,
      perPage,
      currentPage,
      totalTareas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTareasListTable,

      refetchData,
      listTareas,

      // Extra Filters
      statusFilter,
    } = useTareasList();

    listTareas().then((data) => {
      tareasList.value = data.filter((tarea) => !tarea.parent);
    });

    const tareaSelect = (tarea) => {
      if (!tarea.parent && tareasList.id !== tarea.id) {
        tareasList.value.push(tarea);
      }
    };

    const removeTareaFromSelect = (value) => {
      const index = tareasList.value.findIndex((tarea) => tarea.id === value);
      tareasList.value.splice(index, 1);
    };

    return {
      removeTareaFromSelect,
      tareasList,
      tareaSelect,
      refreshItem,
      editItem,
      isAddNewTareaSidebarActive,
      fetchTareas,
      tableColumns,
      perPage,
      currentPage,
      totalTareas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTareasListTable,
      listOfTareas,
      refetchData,
      listTareas,
      deleteItem,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>
